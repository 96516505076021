@import 'reset.css';

* { margin:0; padding:0; }
html, body { height:100lvh; }

body {
	font-family: monospace;
    font-size: 1em;
}

body.light-theme {
	color: #222;
    background-color: #fefefe;

    a { color: #12637f; }

    img {
        filter: none;
    }

    .toggle-theme::after {
        content: '☀';
    }
}

body.dark-theme {
    color: #ccc;
    background-color: #222;

    a {
        color: #408da8;
    }

    .details h2 {
        color: #408da8;
    }

    img {
        filter: brightness(.75) contrast(1.25);
    }

    button {
        color: #ccc;
    }
    .toggle-theme::after {
        content: '☾';
    }
}

@media (prefers-color-scheme: dark) {
    body {
        color: #ccc;
        background-color: #222;

        a {
            color: #408da8;
        }

        .details h2 {
            color: #408da8;
        }
        img {
            filter: brightness(.75) contrast(1.25);
        }
        button {
            color: #ccc;
        }
        .toggle-theme::after {
            content: '☾';
        }
    }
    body.light-theme {
        color: #222;
        background-color: #fefefe;

        a {
            color: #12637f;
        }

        .details h2 {
            color: #12637f;
        }
        img {
            filter: none;
        }
        button {
            color: #222;
        }
        .toggle-theme::after {
            content: '☼';
        }
    }
}

button {
    border: none;
    background: none;
    cursor: pointer;
    color: #222;
}

#player {
    display: flex;
}

.play, .pause {
}

.play::after {
    content: '\25ba';
}

.pause::after {
    content: '\23f8';
}

.toggle-theme::after {
    content: '☀';
}

canvas { display: block; }
a { color: #12637f; }
i { font-style: italic; }

.details h2 {
	margin-bottom: .5em;
	text-transform: lowercase;
    font-size: 1.25em;
    font-weight: bold;
	color: #12637f;
}

#game { position: fixed; }

main {
    display: flex;
    flex-direction: column;
    gap: 1.5em;
    position: relative;
	margin: 0 auto;
    padding: 2em;
	z-index: 1;
    overflow: hidden;
}

.intro {
    display: flex;

    aside {
        display: none;
    }

    .cursor {
        font-size: 14px;
    }

    section {
        display: flex;
        position: relative;
        flex: 1;
        flex-direction: column;
    }
}

.whoami {
	margin-bottom: 1em;
	font-weight: normal;
	text-transform: none;
}

.whoami::after {
	content: '\00a0';
	font-size: .8em;
	line-height: 2em;
	animation: blink 1.25s linear 0s infinite;
}

@keyframes blink {
	0%   { background-color: rgb(12, 88, 114, 1) }
	47%  { background-color: rgb(12, 88, 114, .8) }
	50%  { background-color: rgb(12, 88, 114, 0) }
	97%  { background-color: rgb(12, 88, 114, 0) }
	100% { background-color: rgb(12, 88, 114, .8) }
}

.fields {
	display: table;

    li {
        display: table-row;
        margin: 0;
        line-height: 1.75em;

        label {
            padding-right: 1em;
            display: table-cell;
            text-align: right;
            color: #777;
        }

        .value {
            display: table-cell;
            word-wrap: break-word;
            word-break: break-all;
            white-space: normal;
        }

        address {
            display: inline-block;
        }
    }
}

.toggle-theme {
    position: absolute;
    width: 25px;
    height: 15px;
    top: 0;
    right: 0;
}

.fingerprint {
    display: flex;
    flex-direction: column;

    .fp-row {
        display: flex;
        gap: .5em;

        span {
            white-space: nowrap;
        }
    }
}

img.qr {
    margin-top: 2px;
    width: 115px;
    border-radius: 3px;
}

.details {
    display: flex;
    flex-direction: column;
    gap: 1.5em;

    p {
        line-height: 1.75em;
        text-transform: lowercase;
    }

    article {
        h2::before { content: '#'; }
    }
}

.projects {
	margin: 0.5em 0 0 1.5em;
	list-style-type: disc;

    a {
        line-height: 1.5em;
    }

    li {
        margin-bottom: 1em;
        list-style-type: square;
    }
    li::marker {
        color: #aaa;
    }

    label {
        display: block;
        margin-bottom: .5em;
    }
}

@media only screen and (min-width: 480px) {

	.intro aside {
		display: block;
		padding: 0 1.5em 0.5em 0;

        img {
            width: 100px;
            border-radius: 3px;
        }
	}
}

@media only screen and (min-width: 600px) {

	.intro aside {
		display: block;
		padding: 0 1.5em 0.5em 0;

        img {
            width: 180px;
        }
	}

	main {
        max-width: 600px;
	}
}

@media only screen and (min-width: 768px) {

	.intro aside {
		display: block;
		padding: 0 1.5em 0.5em 0;

        img {
            width: 220px;
        }
	}

    main {
        max-width: 600px;
    }
}
